import React from 'react'
import PropTypes from 'prop-types'
import '../styles/base.scss'
import styles from './layout.module.scss'
import Metadata from '../components/metadata'
import PageTransition from '../components/page-transition'

const Layout = ({ site, page, children, location }) => {
  return (
    <>
      <Metadata
        title={page.title}
        description={site.metaDescription}
        subtitle={site.metaSubtitle}
        shareImage={page.shareImage || site.shareImage}
      />
      <div className={styles.el}>
        <main className={styles.el__main}>
          <PageTransition location={location}>{children}</PageTransition>
        </main>
      </div>
    </>
  )
}

Layout.defaultProps = {
  site: {
    title: '',
    metaDescription: '',
    metaSubtitle: '',
    shareImage: '',
  },
  page: {
    title: '',
    shareImage: '',
    path: '',
  },
  location: {},
}

Layout.propTypes = {
  site: PropTypes.shape({
    title: PropTypes.string,
    metaDescription: PropTypes.string,
    metaSubtitle: PropTypes.string,
    shareImage: PropTypes.string,
  }),
  page: PropTypes.shape({
    title: PropTypes.string,
    shareImage: PropTypes.string,
    path: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

export default Layout
